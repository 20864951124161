.create-topic-page {
  background: #FFFFFF;
  max-width: 1440px;
  width: 100%;
  height: 1024px;
  padding: 24px;
  color: #444444;
  font-family: Roboto;
  font-size: 16px;
}

.create-topic-page .error-message {
  color: #ff0202ad;
  font-size: 14px;
  margin-top: 8px;
}

.create-topic-page .header-title .success-message {
  color: #FFFFFF;
  font-size: 14px;
  margin-top: 8px;
  background-color: #2E7D32;
  border-radius: 8px;
  display: inline-flex;
  align-items: flex-start;
  margin-left: 50%;
  padding: 6px 16px;
}

.create-topic-page .header-title {
  width: 100%;
  border-bottom: 1px solid #C7C7C7;
  //display: inline;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
  gap: 24px;
}

.create-topic-page .header-title h1 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  display: inline;
}
  
.create-topic-page .create-topic-form .text-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-top: 32px;
}

.create-topic-page .create-topic-form p.title-description {
  overflow: hidden;
  color: #828A99;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
  padding: 0;
}

.create-topic-page .create-topic-form {
  width: 100%;
}
.create-topic-page .create-topic-form h2 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.create-topic-page .create-topic-form label {
  font-weight: bold;
  margin-bottom: 4px;
}



.create-topic-page .create-topic-form .fields-inline {
  width: 100%;
  padding: 0 8px 0 0 ;
  display: flex;
  margin-top: 16px;
}

.create-topic-page .create-topic-form .fields-inline .input-text-subject {
  width: 228px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.create-topic-page .create-topic-form .fields-inline .input-text-description {
  display: flex;
  width: 356px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.create-topic-page .create-topic-form .fields-inline .MuiOutlinedInput-root fieldset {
  color: secundary;
  border: none;
  border-bottom: 1px solid #b3adad;
  border-radius: 0;
}

.create-topic-page .create-topic-form .fields-inline .MuiOutlinedInput-root.Mui-focused  fieldset {
  border-bottom: 2px solid #000000;
}

.create-topic-page .create-topic-form .select-component .select-box {
  width: 586px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  padding: 0;
  margin: 0;
  border: 1px solid #E0E0E0;
}
.create-topic-page .create-topic-form .select-component .select-box .MuiSelect-root {
  padding: 0;
  margin: 0;
  height: 40px;
}

.MuiListSubheader-root.sub-header {
  color: #000000; 
}

.MuiMenuItem-root.select-item {
  border-top: 1px solid #E0E0E0;
}

.create-topic-page .create-topic-form .select-component .select-box fieldset {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 0;
  margin: 0;
  border: none;
}

.menu-item-text {
  padding: 0;
}

.create-topic-page .create-topic-form .buttons-box {
  display: inline-flex;
  width: 100%;
  font-size: 14px;
  margin-top: 20px;
}
.create-topic-page .create-topic-form .buttons-box .save-button {
  width: 200px;
  background-color: #ffb300;
  border: none;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 16px;
}

.create-topic-page .create-topic-form .buttons-box .save-button:hover {
  background-color: #ff9800;
}

.create-topic-page .create-topic-form .buttons-box .save-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.create-topic-page .create-topic-form .buttons-box .cancel-button {
  width: 200px;
  background-color: #FFFFFF;
  color: #000000;
  border: none;
  padding: 12px;
  border-radius: 4px;
  
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 16px;
  margin-left: 50%;
  margin-right: 20px;
  border: 1px solid #FFB433;
}

.create-topic-page .create-topic-form .buttons-box .cancel-button:hover {
  background-color: #FFB433;
}
