.select-component {
  background: #FFFFFF;
  width: 100%;
  color: #444444;
  font-family: Roboto;
  font-size: 16px;
}

.select-component .text-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  // margin-top: 20px;
  margin-bottom: 15px;
  display: flex;
  font-weight: 600;
}

.select-component p.title-description {
  overflow: hidden;
  color: #828A99;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
  padding: 0;
}

.select-component .select-box {
    width: 480px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    
    padding: 0;
    margin: 0;
    

}
.select-component .select-box .MuiSelect-root {
  padding: 0;
  margin: 0;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #E0E0E0;
}

.select-component .MuiListSubheader-root.sub-header {
  color: #000000; 
}

.MuiMenuItem-root.select-item {
  border-top: 1px solid #E0E0E0;
}


.select-component .select-box .MuiSelect-root .MuiSelect-icon {
  right: 0px;
  color: #000000;
}

.select-box fieldset {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 0;
  margin: 0;
  border: none;
}

.select-component .menu-item-text {
  padding: 0;
}

.success-message {
  color: #FFFFFF;
  font-size: 14px;
  margin-top: 8px;
  background-color: #2E7D32;
  border-radius: 8px;
  display: inline-flex;
  align-items: flex-start;
  margin-left: 50%;
  padding: 6px 16px;
}


.error-message {
  color: #FFFFFF;
  font-size: 14px;
  margin-top: 8px;
  background-color: #ff0202ad;
  border-radius: 8px;
  display: inline-flex;
  align-items: flex-start;
  margin-left: 50%;
  padding: 6px 16px;
}