.container {
  display: flex;
  height: 100vh;
}

.container-new {
  display: flex;
  height: 100vh;
  padding: 28px 32px;
}



.login-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw;
  padding: 5rem 13rem;
}

.login-section-new {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  padding: 5rem 8rem;
}

.login-form {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.login-form-new {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 60px;
  max-width: 488px !important;
}

.login-error-container {
  max-height: 74px;
  min-height: 74px;
  height: 74px;
  margin-top: 0px !important;
}

.login-form-new input:-webkit-autofill,
.login-form-new input:-webkit-autofill:hover,
.login-form-new input:-webkit-autofill:focus,
.login-form-new textarea:-webkit-autofill,
.login-form-new textarea:-webkit-autofill:hover,
.login-form-new textarea:-webkit-autofill:focus,
.login-form-new select:-webkit-autofill,
.login-form-new select:-webkit-autofill:hover,
.login-form-new select:-webkit-autofill:focus {
  -webkit-text-fill-color: inherit !important;
  -webkit-box-shadow: 0 0 0px 1000px #FFFDF9 inset !important;
  background-color: transparent !important;
  background-image: none !important;
  color: inherit !important;
}

.sign-up-text {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}

@media (max-width: 1439px) {
  .login-section {
    padding: 3.75rem 7rem;
  }

  .login-title {
    margin-bottom: 2.5rem;
  }

  .sign-up-text {
    margin-top: 2.5rem;
  }

}

.mt-0 {
  margin-top: 0 !important;
}