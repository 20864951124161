.success-message-topics-page {
    color: #FFFFFF;
    font-size: 14px;
    background-color: #2E7D32;
    border-radius: 8px;
    display: inline-flex;
    align-items: flex-start;
    margin-left: 50%;
    padding: 6px 16px;
  }
  
  
  .error-message-topics-page {
    color: #FFFFFF;
    font-size: 14px;
    background-color: #ff0202ad;
    border-radius: 8px;
    display: inline-flex;
    align-items: flex-start;
    margin-left: 50%;
    padding: 6px 16px;
  }